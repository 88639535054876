@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
}

.main {
  border-radius: 65% 35% 50% 50% / 41% 68% 32% 59%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.container-hours {
  border-radius: 65% 35% 50% 50% / 41% 68% 32% 59%;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  display: block;
  font-size: 25px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: auto;
  z-index: 999;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: white;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  overflow: auto;
  z-index: 999;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.aspect-w-3 {
  aspect-ratio: 3/2;
}

.aspect-h-2 {
  aspect-ratio: 2/3;
}

.carousel {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.image-container img {
  width: 100%; /* Adjust as needed */
  height: auto;
}

@layer components {
  .circle {
    @apply flex flex-col w-32 h-32 rounded-full bg-circleGreen items-center justify-center;
  }

  .card {
    @apply md:w-64 md:h-48 w-48 h-40  relative;
  }

  .greenBoxh {
    @apply bg-boxGreen opacity-85 absolute w-full h-12 bottom-0 left-0 rounded-b-2xl flex items-center justify-center;
  }

  .greenBoxs {
    @apply bg-boxGreen opacity-85 absolute w-full h-12 bottom-0 left-0 rounded-b-2xl flex items-center justify-center;
  }

  .infoBox {
    background-color: white; /* Bílé pozadí pro nový box */
    color: black; /* Černá barva textu */
    padding: 10px; /* Padding pro text v boxu */
    /* Další potřebné styly */
  }
}
